
import {defineComponent, reactive, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import * as Yup from "yup";
import Error from "@/components/base/Error.vue";
import UserService from "@/core/services/UserService";
import {useToast} from "vue-toast-notification";

export default defineComponent({
  name: "sign-in",
  components: {
    Error,
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const verifyCode = ref(false);
    const verifySubmitting = ref(false);
    const loginSubmitting = ref(false);
    const signError = ref({isError: false, message: ''})
    const state = reactive({
      email: '',
      password: '',
      code: '',
    })
    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().required().label("Password"),
    });
    const verify = Yup.object().shape({
      code: Yup.string().required().label("Code"),
    })
    //Form submit function
    const onSubmitLogin = (values) => {
      loginSubmitting.value = true
      store.dispatch(Actions.LOGOUT);
      UserService.login(values).then(res => {
        if (res.verifyCode == false) {
          store.commit(Mutations.SET_AUTH, res)
          if (res.resetPassword) {
            router.push({name: "changePassword"});
          } else {
            router.push({name: "dashboard"});
          }
        } else {
          signError.value.isError = false;
          verifyCode.value = res.verifyCode;
        }
      }).catch(error => {
        signError.value = {isError: true, message: error.data.message}
      }).finally(() => {
        loginSubmitting.value = false
      })
    };

    const present = () => {
      verifySubmitting.value = true
      UserService.present(state).then(() => {
        useToast().success('We sent you new MFA code', {position: 'top-right'})
      }).finally(() => {
        verifySubmitting.value = false
      })
    }
    const onCodeSubmit = () => {
      verifySubmitting.value = true
      UserService.verify(state).then(res => {
        store.commit(Mutations.SET_AUTH, res)
        if (res.resetPassword) {
          router.push({name: "changePassword"});
        } else {
          router.push({name: "home"});
        }
      }).catch(error => {
        console.log(error)
        signError.value.isError = true;
        signError.value.message = error.data.message
        console.log(error)
      }).finally(() => {
        verifySubmitting.value = false
      })
    }

    return {
      onSubmitLogin,
      verifySubmitting,
      loginSubmitting,
      login,
      verifyCode,
      state,
      onCodeSubmit,
      verify,
      signError,
      present,
    };
  },
});
